import React from 'react'

import PropTypes from 'prop-types'

const Profile = ({name, type, bio, bioTheme, bgImage, avatarImage, direction}) => {
  var profileContainerClassName = "profile-container"
  if (direction === 'reverse') {
    profileContainerClassName += " profile-container--reverse"
  }

  return (
    <section className="profile" style={{ backgroundImage: "url('" + bgImage + "')"}}>
      <div className={profileContainerClassName}>
        <article className="profile-info">
          <div className="profile-info__name">{name}</div>
          <div className={"profile-info__type profile-info__type--" + type}>{type}</div>
          <p className={"profile-info__bio profile-info__bio-theme-" + bioTheme}>
            {bio}
          </p>
        </article>
        <aside className="profile-avatar">
          <img src={avatarImage} alt={name}/>
        </aside>
      </div>
    </section>
  )
}

Profile.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  bio: PropTypes.string,
  bgImage: PropTypes.string,
  avatarImage: PropTypes.string,
  flexDirection: PropTypes.string,
  bioTheme: PropTypes.string,
}

Profile.defaultProps = { 
  name: '',
  type: '',
  bio: '',
  bgImage: '',
  avatarImage: '',
  direction: '',
  bioTheme: 'light',
}

export default Profile