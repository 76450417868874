import React from 'react'

import Layout from '../components/layout'
import Profile from '../components/Profile'

import profileRex from '../assets/images/profile-rex.png'
import profileRexBg from '../assets/images/profile-rex-bg.png'
import profilePaleontologists from '../assets/images/profile-paleontologists.png'
import profilePaleontologistsBg from '../assets/images/profile-paleontologists-bg.png'
import profileAstronauts from '../assets/images/profile-astronauts.png'
import profileAstronautsBg from '../assets/images/profile-astronauts-bg.png'
import spanx from '../assets/images/profile-spanx.png'
import spanxBg from '../assets/images/profile-spanx-bg.png'
import pirates from '../assets/images/profile-pirates.png'
import piratesBg from '../assets/images/profile-pirates-bg.png'
import rocateer from '../assets/images/profile-rocateer.png'
import rocateerBg from '../assets/images/profile-rocateer-bg.png'

const Characters = () => {

  const profileMetadata = [
    {
      name: 'Captain Rex',
      type: 'hero',
      bgImage: profileRexBg,
      bio: `Young pilot of the Dinonaut Corps. Has dreamed of leading
              a team back to his homeworld his whole life.`,
      avatarImage: profileRex,
    },
    {
      name: 'Paleontologists',
      type: 'villain',
      bgImage: profilePaleontologistsBg,
      bio: `All this time they new dinosaurs never went extinct,
              and have made a fortune off of it. They're going to protect
              that secret no matter what.`,
      avatarImage: profilePaleontologists,
      direction: "reverse",
      bioTheme: 'dark',
    },
    {
      name: 'Astronauts',
      type: 'villain',
      bgImage: profileAstronautsBg,
      bio: `These guys claimed dinosaur inventions as their own.
      They'll do anything to keep the cat in the bag.`,
      avatarImage: profileAstronauts,
    },
    {
      name: 'Dr. Elijah Spanx',
      type: 'villain',
      bgImage: spanxBg,
      bio: `Ace chimp pilot for hire to the highest banana-bidder.`,
      avatarImage: spanx,
    },
    {
      name: 'Ghost Pirates',
      type: 'lost',
      bgImage: piratesBg,
      bio: `These two nincompoops got their ship caught in the
            Bermuda Triangle in the 1700s. They've been floating
            through space ever since.`,
      avatarImage: pirates,
    },
    {
      name: 'The Rocateer',
      type: 'cat',
      bgImage: rocateerBg,
      bio: `This fellow space traveler is just passing through.
            He doesn't mean anyone any harm, so make sure you
            extend the same kindness.`,
      avatarImage: rocateer,
      direction: "reverse",
      bioTheme: 'dark',
    }
  ]

  return (
    <Layout>
      {
        profileMetadata.map(profile => 
          <Profile key={profile.name} {...profile} />
        )
      }
    </Layout>
  )
}

export default Characters